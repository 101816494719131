/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/jquery-validation@1.21.0/dist/jquery.validate.min.js
 * - /npm/@popperjs/core@2.11.8/dist/umd/popper.min.js
 * - /npm/tippy.js@6.3.7/dist/tippy.umd.min.js
 * - /npm/clipboard@2.0.11/dist/clipboard.min.js
 * - /npm/semantic-ui-tab@2.5.0/tab.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
